@import 'variable';
@import 'mixin';

/* ======================================================================
 p_top_movie
====================================================================== */
.p_top_movie{
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background: url('../../assets/movie/p_top_mv.jpg') no-repeat;
  background-size: cover;
  @include sp{
    display: none;
  }
}

.p_top_movie_sp{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -100;
  background: url('../../assets/movie/sp/p_top_mv.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  display: none;
  @include sp{
    display: block;
  }
}
/* ======================================================================
 p_top_mv__first_cont
====================================================================== */
.p_top_mv__first_cont{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction : column;
  justify-content: center;
  margin-left: 6%;
  .c_btn_1_txt{
    font-size: 1.8rem;
    font-weight: bold;
    font-weight: 700;
  }
  @include h_change{
    margin-left: 20px;
  }
  @include sp{
    margin: 0 auto;
    padding: 0 20px;
    height: calc(100vh - 84px);
  }
}
/* ======================================================================
 p_top_mv__catch
====================================================================== */
.p_top_mv__catch{
  position: relative;
  font-family:$font_2;
  font-size: 6.4rem;
  line-height: 1.5;
  color: $white;
  z-index: 4;
  .sub{
    font-size: 5.2rem
  }
  @include sp {
    font-size: 3.5rem;
    .sub{
      font-size: 3.0rem
    }
  }
}
/* ======================================================================
 p_top_mv_subtxt
====================================================================== */
.p_top_mv_subtxt{
  font-size: 1.8rem;
  font-family:$font_2;
  color: $white;
  margin-top: 40px;
  position: relative;
  z-index: 4;
}
/* ======================================================================
 p_top_mv_txtfade
====================================================================== */
.p_top_mv_txtfade{
  position: relative;
  opacity: 0;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  display: inline-block;
  margin: 0 -4px;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
  &.fadeInUp{
    opacity: 1;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
    @include sp {
    margin: 0 -5px;
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(24px);
    -webkit-transform: translateY(24px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(24px);
    -webkit-transform: translateY(24px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0px);
  }
}


/* ======================================================================
 p_top_triangle_1
====================================================================== */
.p_top_triangle_1 , .p_top_triangle_2{
  transition: $all_2;
  position: absolute;
  top: 0;
  transform:translate(530px , -857px);
  &:before{
    content:"";
    border-style: solid;
    border-width: 857px 530px 0 0;
    border-color: $color_sub_2 transparent transparent transparent;
    display: block;
    transition: $all;
  }
  &.active{
    transform:none;
  }
  @include sp {
    transform:translate(220px , -550px);
    &:before{
      border-width: 550px 220px 0 0;
    }
  }
}

.p_top_triangle_1{
  left: -24px;
  opacity: 0.5;
}
.p_top_triangle_2{
  left: -84px;
  opacity: 0.8;
  @include sp{
    left: -64px;
  }
}


/* ======================================================================
 p_top_mv_txt
====================================================================== */
.p_top_mv_txt{
  position: relative;
  z-index: 4;
  font-family:$font_2;
  color: $white;
  font-size: 2.2rem;
  .c_ttl_1{
    font-size:3.4rem;
    line-height: 2;
    margin-bottom: 60px;
    &.light{
      .en{
        &:before {
          top: 20px;
        }
      }
    }
    .sub{
      font-size:2.6rem;
    }
  }
  .txt_appear{
    overflow: hidden;
    margin-bottom: 56px;
    position: relative;
    display: block;
    
    .inner{
      position: relative;
      top: 1.6vw;
      left: 0;
      transition-delay: .2s;
      opacity: 0;
      transition: $all_2;
    }
    &.active{
      .inner{
        top: 0;
        opacity: 1;
      }
    }
  }
  @include sp {
    font-size: 1.5rem;
    .txt_appear{
      margin-bottom: 32px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .c_ttl_1{
      font-size:3.0rem;
      line-height: 1.4;
      .sub{
        font-size:2.2rem;
      }
    }
  }
}

/* ======================================================================
 p_top_mv__mount
====================================================================== */
.p_top_mv__mount{
  position: absolute;
  right: 0;
  width: 789px;
  top: 380px;
  opacity: 0.8;
  .img{
    width: 100%;
  }
   @include sp{
    width: 383px;
    right: -30%;
   }
}
/* ======================================================================
 p_top_mv__scroll
====================================================================== */
.p_top_mv__scroll{
  font-family:$font_2;
  position: absolute;
  bottom: 0;
  right: 20%;
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  font-size: 1.4rem;
  overflow: hidden;
  width: 20px;
  height: 160px;
  color: $white;
  z-index: 4;
  &:before{
    position: absolute;
    width: 1px;
    content: "";
    display: block;
    background-color: $white;
    left: 0;
    transition: $all;
    animation-name: scrollAnim;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 160px;
  }
}

@keyframes scrollAnim {
  0% {
    top: -156px;
  }
  45% {
    top: 0;
  }
  70% {
    top: 160px;
  }
  100% {
    top: 160px;
  }
}

/* ======================================================================
 p_top
====================================================================== */
// p_top_first_cont
.p_top_bg{
  position: relative;
  background: #fff;
  .p_top_bg_1,.p_top_bg_2{
    position: absolute;
    width: 400px;
    height: 400px;
    &:before{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }
  }
  .p_top_bg_1{
    top: 0;
    left: 0;
    &:before{
      border-width: 400px 400px 0 0;
      border-color: $color_12 transparent transparent transparent;
    }
  }
  .p_top_bg_2{
    top: 400px;
    right: 0;
    &:before{
      border-width: 0 0 400px 400px;
      border-color: transparent transparent $color_13 transparent;
      top: 0;
    }
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      border-width: 0 400px 400px 0;
      border-color: transparent $color_13 transparent transparent;
      top: 400px;
    }
  }
  @include sp{
  .p_top_bg_1,.p_top_bg_2{
    width: 200px;
    height: 200px;
  }
  .p_top_bg_1{
    &:before{
      border-width: 200px 200px 0 0;
    }
  }
  .p_top_bg_2{
    top: 30%;
    &:before{
      border-width: 0 0 200px 200px;
    }
    &:after{
      border-width: 0 200px 200px 0;
      top: 200px;
    }
  }
  }
}
/* ======================================================================
 p_top_cont
====================================================================== */
// p_top_cont
.p_top_cont{
  position: relative;
  padding: 80px 0 0;
  background: #fff;
  width: 100%;
  &.secBottom{
    padding-bottom: 80px;
  }
}
/* ======================================================================
 p_top_service
====================================================================== */
// p_top_service__list
.p_top_service__list{
  .p_top_service__list_item{
    margin-bottom: 24px;
  }

  @include tab_content{
    display: flex;
    justify-content: space-between;
    flex-wrap : wrap;
    .p_top_service__list_item{
      width: calc(50% - 12px);
    }
  }
  @include pc{
    display: flex;
    justify-content: space-between;
    flex-wrap : wrap;


    .p_top_service__list_item{
      width: calc(33.33333% - 12px);
    }
    .p_top_service__list_item.half{
      width: calc(50% - 12px);
    }
  }
}
// p_top_service__card
.p_top_service__card{
  position: relative;
  border: 1px solid $color_11;
  padding: 24px 22px;
  display: flex;
  height: 100%;
  transition: $all;
  background: $white;
  z-index: 3;
  &:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0, -50%);
    width: 3px;
    height: 100px;
    background: $color_6;
  }
  .pic{
    width: 120px;
    height: 120px;
    background:$color_10;
    margin-right: 14px;
    overflow: hidden;
    .img{
      width: 100%;
    }
    &.pic01{
      background: url('../../assets/img/p_top/p_top_service_1.png') no-repeat;
      background-size: cover;
    }
    &.pic02{
      background: url('../../assets/img/p_top/p_top_service_2.png') no-repeat;
      background-size: cover;
    }
    &.pic03{
      background: url('../../assets/img/p_top/p_top_service_3.png') no-repeat;
      background-size: cover;
    }
    &.pic04{
      background: url('../../assets/img/p_top/p_top_service_4.png') no-repeat;
      background-size: cover;
    }
    &.pic05{
      background: url('../../assets/img/p_top/p_top_service_5.png') no-repeat;
      background-size: cover;
    }
     @include sp{
      width: 116px;
      height: 116px;
     }
  }
  .cont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex:1;
    .ttl{
      font-family: $font_2;
      color: $color_3;
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
    .txt{
      font-size: 1.4rem
    }
  }
  .c_btn_6{
    margin-top: auto;
    margin-left: auto;
  }
}

.hover{
  .p_top_service__card{
    &:hover {
      background: $color_3;
      .ttl , .txt{
        color: $white;
      }
      &:before{
        background: $white;
      }
      .c_btn_6{
        color: $white;
        &:after {
          border-top: 1px solid $white;
          border-right: 1px solid $white;
        }
      }
      .pic{
        &.pic01{
          background: url('../../assets/img/p_top/p_top_service_1_on.png') no-repeat;
          background-size: cover;
        }
        &.pic02{
          background: url('../../assets/img/p_top/p_top_service_2_on.png') no-repeat;
          background-size: cover;
        }
        &.pic03{
          background: url('../../assets/img/p_top/p_top_service_3_on.png') no-repeat;
          background-size: cover;
        }
        &.pic04{
          background: url('../../assets/img/p_top/p_top_service_4_on.png') no-repeat;
          background-size: cover;
        }
        &.pic05{
          background: url('../../assets/img/p_top/p_top_service_5_on.png') no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

/* ======================================================================
 p_top_strengths
====================================================================== */
// p_top_strengths
.p_top_strengths{
  display: flex;
  flex-wrap : wrap;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  .p_top_strengths_item{
    margin-bottom: 24px;
  }
  @include pc{
    .p_top_strengths_item{
      width: calc(33.33333333% - 10px);
      
    }
    &:after{
      content:"";
      display: block;
      width: calc(33.33333333% - 10px);
    }
  }
  @include tab_content{
    .p_top_strengths_item{
      width: calc(33.33333333% - 8px);
      
    }
    &:after{
      content:"";
      display: block;
      width: calc(33.33333333% - 8px);
    }
  }
}

.p_top_cont_bg_1{
  background: $color_4;
  position: relative;
  padding: 80px 0;
  width: 100%;
  @include sp{
    padding: 60px 0;
  }
}